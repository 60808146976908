import React from "react"
import PropTypes from "prop-types"


import SEO from "../components/seo"
import { LocaleContext } from "../components/layout";

const NotFoundPage = ({pageContext: { locale }, location }) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]

  return (
  <>
    <SEO title="404: Not found" />
    <h1>DA IST WAS SCHIEF GELAUFEN</h1>
    <p>Leider existiert diese Seite nicht.</p>
  </>
)}

export default NotFoundPage


  NotFoundPage.propTypes = {
    data: PropTypes.shape({
      posts: PropTypes.object.isRequired,
      homepage: PropTypes.object.isRequired
    }).isRequired,
    pageContext: PropTypes.shape({
      locale: PropTypes.string.isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
  }